import React from "react";
import { graphql } from "gatsby";
import styles from "./smeFinance.module.css";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { getLocaleWiseLabel as t } from "../../../lib/localeUtils";

type AppProps = {
  labelArr: Array<object>;
};

const SmeFinance = ({ labelArr }: AppProps) => {
  return (
    <div>
      <div className={styles.pageTop}>
        <img src="/assets/images/SME_finance_top_picture.png" className={styles.pageTopImage} />
        <Container>
          <Row className="justify-content-end">
            <Col className={styles.pageTopCol} md={8} sm={8}>
              <h1 className={styles.pageTopTitle}>
                {t(labelArr, "SME_finance_top_title1", "heb")}
              </h1>
              <h1 className={styles.pageTopTitle2}>
                {t(labelArr, "SME_finance_top_title2", "heb")}
              </h1>
              <p className={styles.pageTopText}>{t(labelArr, "SME_finance_top_body", "heb")}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.financeBodyContainer}>
        <Row className="justify-content-end mb-3">
          <Col md={7}>
            <p className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_def_title", "heb")}
            </p>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_def_body", "heb")}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3">
          <Col md={7}>
            <b className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_cont_title", "heb")}
            </b>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_cont_body", "heb")}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3">
          <Col md={7}>
            <p className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_desc_title", "heb")}
            </p>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_desc_body", "heb")}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3">
          <Col md={7}>
            <p className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_risk_title", "heb")}
            </p>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_risk_body", "heb")}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3">
          <Col md={7}>
            <p className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_like_title", "heb")}
            </p>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_like_body", "heb")}
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={styles.financeSolContainer}>
        <Row className="justify-content-center">
          <Col md={8}>
            <p className={styles.financeBodyTitle}>
              {t(labelArr, "SME_finance_body_sol_title", "heb")}
            </p>
            <p className={styles.financeBodyBody}>
              {t(labelArr, "SME_finance_body_sol_body", "heb")}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SmeFinance;
